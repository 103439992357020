import { Injectable, inject } from '@angular/core';
import {
  ChallengeFormDataType,
  CommonRepositoryAbstract,
  FileUtils,
} from '@freddy/common';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { TenantService } from '../../../core/auth/services/tenant.service';
import {
  Challenge,
  MissionTypeEnum,
  SlidingPuzzleMissionModel,
  TranslatedContent,
} from '@freddy/models';
import { AssetService } from '../../../core/assets/asset.service';
import { Firestore } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';
import { AssetRepository } from '../../../core/assets/asset.repository';
import { Locale } from 'locale-enum';

@Injectable({
  providedIn: 'root',
})
export class ChallengeRepository extends CommonRepositoryAbstract<Challenge> {
  private readonly assetService = inject(AssetService);
  private readonly assetRepository = inject(AssetRepository);
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);
    super(firestore);
  }

  private static readonly DOC_PATH = 'challenges';

  async createChallenge(
    formDataChallenge: ChallengeFormDataType,
  ): Promise<Challenge> {
    const uploadedAssets = await this.uploadChallengeAssets(formDataChallenge);
    const challenge: Challenge = {
      ...this.mapper(formDataChallenge, uploadedAssets),
      organization: this.tenantService.currentOrganizationSlug,
    };
    await firstValueFrom(this.create(challenge));
    return challenge;
  }

  async updateChallenge(
    formDataChallenge: ChallengeFormDataType,
  ): Promise<Challenge> {
    const uploadedAssets = await this.uploadChallengeAssets(formDataChallenge);
    const challenge = this.mapper(formDataChallenge, uploadedAssets);
    await firstValueFrom(this.update(challenge));
    return challenge;
  }

  async updateChallengeAssets(
    formDataChallenge: ChallengeFormDataType,
  ): Promise<Challenge> {
    if (formDataChallenge.uid) {
      const previousChallenge = await firstValueFrom(
        this.get(formDataChallenge.uid),
      ).catch(() => {});
      if (previousChallenge?.assets?.length) {
        await Promise.all(
          previousChallenge.assets.map((assetUid) =>
            this.assetRepository.delete({ uid: assetUid }),
          ),
        );
      }
    }
    const uploadedAssets = await this.uploadChallengeAssets(formDataChallenge);
    return this.mapper(formDataChallenge, uploadedAssets);
  }

  private async uploadChallengeAssets(
    formDataChallenge: ChallengeFormDataType,
  ): Promise<string[]> {
    const uploadedAssets: string[] = [];

    // Handle SLIDING_PUZZLE challenges
    if (
      formDataChallenge.metadata.missionType === MissionTypeEnum.SLIDING_PUZZLE
    ) {
      const image =
        formDataChallenge.metadata[MissionTypeEnum.SLIDING_PUZZLE]?.image;
      if (image && FileUtils.isBase64Image(image)) {
        const uploadedPath = await this.assetService.uploadAndCreateAsset(
          FileUtils.convertBase64ToFile(image, 'puzzle_image.png'),
          { type: 'PUZZLE' },
        );
        formDataChallenge.metadata[MissionTypeEnum.SLIDING_PUZZLE]!.image =
          uploadedPath.path;
        uploadedAssets.push(uploadedPath.uid);
      }
    }

    // Handle intro images if there are any
    if (formDataChallenge.intro) {
      for (const locale in formDataChallenge.intro) {
        const challengeContent = formDataChallenge.intro[locale as Locale];
        if (challengeContent?.images?.length) {
          for (const file of challengeContent.images) {
            const uploadedPath = await this.assetService.uploadAndCreateAsset(
              file,
              { type: 'intro', locale },
            );
            uploadedAssets.push(uploadedPath.uid);
          }
        }
      }
    }

    return uploadedAssets;
  }
  private getAssetIdFromPath(path: string): string | null {
    return path?.split('/').pop() || null;
  }

  async cloneChallenge(challenge: Challenge): Promise<Challenge> {
    const newUid = GuidUtils.generateUuid();

    // Create a mapping of original asset IDs to cloned assets
    const assetMapping = new Map<string, { uid: string; path: string }>();

    // Clone all assets and build the mapping
    await Promise.all(
      (challenge.assets || []).map(async (assetId) => {
        const clonedAsset = await this.assetService.copyAssetById(assetId);
        assetMapping.set(assetId, {
          uid: clonedAsset.uid,
          path: clonedAsset.path,
        });
      }),
    );

    // Create the cloned challenge with updated asset references
    const clonedChallenge: Challenge = {
      ...challenge,
      uid: newUid,
      assets: (challenge.assets || []).map(
        (assetId) => assetMapping.get(assetId)?.uid || assetId,
      ),
      updatedAt: new Date(),
    };

    // Special handling for SLIDING_PUZZLE type
    if (
      clonedChallenge.metadata &&
      clonedChallenge.metadata.missionType === MissionTypeEnum.SLIDING_PUZZLE &&
      clonedChallenge.metadata[MissionTypeEnum.SLIDING_PUZZLE]
    ) {
      const puzzleModel = clonedChallenge.metadata[
        MissionTypeEnum.SLIDING_PUZZLE
      ] as SlidingPuzzleMissionModel;
      if (puzzleModel.image) {
        const assetId = this.getAssetIdFromPath(puzzleModel.image);
        if (assetId) {
          const newAsset = assetMapping.get(assetId);
          if (newAsset) {
            puzzleModel.image = newAsset.path;
          }
        }
      }
    }

    return clonedChallenge;
  }

  protected getDocPath(): string {
    return `${this.tenantService.getOrganizationPrefixPath()}${ChallengeRepository.DOC_PATH}`;
  }

  private mapper(
    formDataChallenge: ChallengeFormDataType,
    uploadedAssets: string[],
  ): Challenge {
    return {
      uid: formDataChallenge.uid ?? GuidUtils.generateUuid(),
      assets: uploadedAssets,
      intro: this.removeImagesFromTranslatedContent(formDataChallenge.intro),
      common: {
        ...formDataChallenge.common,
      },
      metadata: {
        ...formDataChallenge.metadata,
      },
      collectionIds: formDataChallenge.collectionIds ?? [],
    };
  }

  private removeImagesFromTranslatedContent<T extends { images?: any }>(
    content: TranslatedContent<T>,
  ): TranslatedContent<Omit<T, 'images'>> {
    const result: TranslatedContent<Omit<T, 'images'>> = {};

    if (!content) return result;

    for (const key in content) {
      if (Object.hasOwn(content, key)) {
        const locale = key as Locale;
        const localeContent = content[locale];

        if (localeContent) {
          // Safely extract images from content
          const { images, ...rest } = localeContent;
          result[locale] = rest as Omit<T, 'images'>;
        }
      }
    }

    return result;
  }
}
